import { permissoesEnum, entidadeTipoEnum } from "@/types/enums";
import { subject } from "@casl/ability";

export default defineNuxtRouteMiddleware((to) => {
  const { $ability } = useNuxtApp();

  const routeName = String(to.name);
  const id = Number(to.params.id);

  // página de listagem
  if (
    routeName === "redes" &&
    $ability.cannot(permissoesEnum.VER_ROTA, entidadeTipoEnum.REDE)
  ) {
    return navigateTo({
      name: "index",
    });
  }

  // página de cadastro
  if (
    routeName === "redes-cadastrar" &&
    $ability.cannot(permissoesEnum.CADASTRAR_REDE, entidadeTipoEnum.REDE)
  ) {
    return navigateTo({
      name: "redes",
    });
  }

  // página de visualização
  if (
    routeName === "redes-visualizar-id" &&
    $ability.cannot(
      permissoesEnum.VISUALIZAR_REDE,
      subject(entidadeTipoEnum.REDE, {
        id,
      })
    )
  ) {
    return navigateTo({
      name: "redes",
    });
  }

  // página de edição
  if (
    routeName === "redes-editar-id" &&
    $ability.cannot(
      permissoesEnum.EDITAR_REDE,
      subject(entidadeTipoEnum.REDE, {
        id,
      })
    ) &&
    $ability.cannot(
      permissoesEnum.EDITAR_PARCIALMENTE_REDE,
      subject(entidadeTipoEnum.REDE, {
        id,
      })
    ) &&
    $ability.cannot(
      permissoesEnum.CADASTRAR_EDITAR_REMOVER_CONTATOS_REDE,
      subject(entidadeTipoEnum.REDE, {
        id,
      })
    )
  ) {
    return navigateTo({
      name: "redes-visualizar-id",
      params: {
        id,
      },
    });
  }

  // página de convidar contato
  if (
    routeName === "redes-editar-id-convidar-usuario" &&
    $ability.cannot(
      permissoesEnum.CADASTRAR_EDITAR_REMOVER_CONTATOS_REDE,
      subject(entidadeTipoEnum.REDE, {
        id,
      })
    )
  ) {
    return navigateTo({
      name: "redes-editar-id",
      params: {
        id,
      },
    });
  }
});
